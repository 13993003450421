import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicForm from '../components/DynamicForm/DynamicForm'
import ReactMarkdown from 'react-markdown'

const formTemplate = ( { data: { strapi: { form } }, location } ) => {

	return (
		<>
			<SEO title={ form.formTitle } pathname={ location.pathname } />

			<div className="form">
				<div className="form__wrapper wrapper">

					<h1 className="form__header">{ form.formTitle }</h1>

					{ form.formDescription &&
						<ReactMarkdown
							className="form__description"
							escapeHtml={ false }
							source={ form.formDescription }
						/>
					}

					<div className="form__body-content">
						{ form && form.content &&
							<DynamicForm components={ form.content } title={ form.formTitle } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default formTemplate

export const query = graphql`
	query formQuery($id: ID!) {
		strapi {
			form(id: $id) {
				id
				formTitle
				formDescription
				content {
					... on StrapiQuery_ComponentFormsTextArea {
						id
						textAreaQuestion
						textAreaPlaceholder
						required
					}
					... on StrapiQuery_ComponentFormsMultipleChoice {
						id
						multipleChoiceTitle
						required
						optionList {
							option
							id
						}
					}
					... on StrapiQuery_ComponentFormsRadio {
						id
						radioQuestion
						required
						radioOptions {
							option
							id
						}
					}
					... on StrapiQuery_ComponentFormsTextField {
						id
						questions {
							id
							type
							textLabel
							textPlaceholder
							required
						}
					}
					... on StrapiQuery_ComponentFormsSectionTitle {
						id
						formSectionTitle
						formSectionDescription
					}
					... on StrapiQuery_ComponentFormsRatingScale {
						id
						ratingScaleText
					}
				}
			}
		}
	}
`