import React, { useState, useRef } from 'react'
import axios from 'axios'

import { FaSpinner } from 'react-icons/fa'

import MultipleChoice from './forms/MultipleChoice'
import TextArea from './forms/TextArea'
import Radio from './forms/Radio'
import TextField from './forms/TextField'
import SectionTitle from './forms/SectionTitle'
import RatingScale from './forms/RatingScale'

const EMAIL_KEY = process.env.EMAIL_KEY || "kieron123"

export default function DynamicZone( { components, title } ) {

	const form = useRef();

	const [ error, setError ] = useState('')
	const [ data, setData ] = useState({
		formTitle: title
	})
	const [ formSuccess, setFormSuccess ] = useState( false )
	const [ loading, setLoading ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		setLoading( true )

		const dataToSend = {
			data,
			EMAIL_KEY
		}

		try {
			const result = await axios.post('/api/email', dataToSend);

			if ( result.status === 200 ) {
				setFormSuccess( true )
				setLoading( false )
			}
		} catch ( error ) {
			setError('Error')
			setLoading( false )
		}
	}

	return (
		<>
			{ !formSuccess ? (
				<form className={`form__form ${ error.length >= 1 ? 'error' : '' }`} onSubmit={ handleSubmit } ref={ form }>
					{ components.map(( comp, index ) => {
						switch (comp.__typename) {
							case 'StrapiQuery_ComponentFormsMultipleChoice':
								return <MultipleChoice key={ index } input={ comp } question={ index } setData={ setData } />
							case 'StrapiQuery_ComponentFormsTextArea':
								return <TextArea key={ index } input={ comp } question={ index } setData={ setData } />
							case 'StrapiQuery_ComponentFormsRadio':
								return <Radio key={ index } input={ comp } question={ index } setData={ setData } />
							case 'StrapiQuery_ComponentFormsTextField':
								return <TextField key={ index } input={ comp } question={ index } setData={ setData } />
							case 'StrapiQuery_ComponentFormsSectionTitle':
								return <SectionTitle key={ index } input={ comp } question={ index } setData={ setData }/>
							case 'StrapiQuery_ComponentFormsRatingScale':
								return <RatingScale key={ index } input={ comp } question={ index } setData={ setData } />
							default:
								return null
						}
					} ) }
					{ error.length >= 1 ? (
						<div className="form__error-container">
							<span className="form__error-message">Sorry, we have been unable to submit your form.</span>
							<span className="form__error-message">Please try again later.</span>
						</div>
					) : (
						<button className="form__submit" type="submit">Send</button>
					) }

					{ loading &&
						<div className="form__loading-container">
							<div className="form__loading-background"></div>
							<div className="form__loading-text-container">
								<FaSpinner className="loader" />
								<h3 className="form__loading-text">Processing Form</h3>
							</div>
						</div>
					}
				</form>
				) : (
					<p className="form__success">
						Thank you for submitting the form.
					</p>
			) }
		</>
	)
}