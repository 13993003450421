import React, { useState, useEffect } from 'react'

export default function TextArea ( props ) {

	const { question, setData } = props
	const { textAreaQuestion, textAreaPlaceholder, required } = props.input
	const [ messageDetails, setMessageDetails ] = useState("")

	useEffect(() => {
		const data = {}
		data[ question ] = { question: textAreaQuestion, answer: messageDetails }
		setData( state => ({
			...state,
			...data
		})
		)
	}, [ messageDetails, question, textAreaQuestion, setData ])

	const generateId = ( label, index ) => {

		return `${ label.replace(/\s/g, "").slice(0, 7) }-${ index }`
	}

	return (
		<div className="form__fields-wrapper">
			<div className="form__input-container">
				<label className="form__label" htmlFor={ generateId( textAreaQuestion, question ) }>{ textAreaQuestion } { required ? '' : '(optional)' }</label>
				<textarea
					onChange={ e => { setMessageDetails(e.target.value) }}
					value={ messageDetails }
					className="form__text-area"
					id={ generateId( textAreaQuestion, question ) }
					rows="6"
					placeholder={ textAreaPlaceholder ? textAreaPlaceholder : 'Type answer here' }
					required={ required } />
			</div>
		</div>
	)
}
