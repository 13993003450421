import React, { useState, useEffect } from 'react'

export default function MultipleChoice ( props ) {

	const { setData, question } = props
	const { multipleChoiceTitle, optionList, required } = props.input
	const [ checkedArray, setCheckedArray ] = useState([])

	const handleOnClick = e => {
		const checkedArrayCopy = [ ...checkedArray ]
		const { value } = e.target
		if( e.target.checked ){
			checkedArrayCopy.push( value )
		} else {
			const foundBrandIndex = checkedArray.findIndex( option => option === value )
			foundBrandIndex !== -1 && checkedArrayCopy.splice( foundBrandIndex, 1 )
		}

		setCheckedArray( checkedArrayCopy )
	}

	const generateId = ( label, index ) => {

		return `${ label.replace(/\s/g, "").slice(0, 7) }-${ index }`
	}

	useEffect(() => {
		const data = {}
		data[ question ] = { question: multipleChoiceTitle, answer: checkedArray }
		setData( state => ({
			...state,
			...data
		}) )
	}, [ checkedArray, question, multipleChoiceTitle, setData ])

	return (
		<div className="form__fields-wrapper">
			<p className="form__list-label">{ multipleChoiceTitle } { required ? '' : '(optional)' }</p>
			<div className="form__list">
				{ optionList.map( option => (
					<div className="form__input-container" key={ option.id }>
						<label className="form__checkbox-label" htmlFor={ option.id }>
							<input
								name={ generateId( multipleChoiceTitle, option.id ) }
								className="form__checkbox-input"
								type="checkbox"
								id={ option.id }
								onChange={ e => handleOnClick( e )}
								value={ option.option }
								required={ required } />
							<span className="form__checkbox-text">{ option.option }</span>
						</label>
					</div>
				))}
			</div>
		</div>
	)
}
