import React, { useState, useEffect } from 'react'

export default function TextField ( props ) {

	const { question, setData } = props
	const { questions } = props.input
	const [ state, setState ] = useState({})

	const handleChange = ( e, index, question ) => {
		state[ index ] = { answer: e.target.value, question }
		setState( prevState => ({
			...prevState,
			...state
		}))
	}

	useEffect(() => {
		const data = {}
		data[ question ] = state
		setData( prevState => ({
			...prevState,
			...data
		}) )
	}, [ state, question, setData ])

	const generateId = ( label, index ) => {

		return `${ label.replace(/\s/g, "").slice(0, 7) }-${ index }`
	}

	return (
		<div className="form__fields-wrapper">
			{ questions.map( ( input, index ) => (
				<div className="form__input-container" key={ index }>
					<label className="form__label" htmlFor={ generateId( input.textLabel, index ) }>{ input.textLabel } { input.required ? '' : '(optional)' }</label>
					<input
						onChange={ e => handleChange( e, index, input.textLabel ) }
						value={ state[ index ] ? state[ index ].answer : '' }
						className="form__input"
						id={ generateId( input.textLabel, index ) }
						type={ input.type }
						placeholder={ input.placeholder ? input.placeholder : input.textLabel }
						required={ input.required } />
				</div>
			) ) }
		</div>
	)
}
