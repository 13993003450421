import React, { useEffect } from 'react'

export default function SectionTitle ( props ) {

	const { setData, question } = props
	const { formSectionTitle, formSectionDescription } = props.input

	useEffect(() => {
		const data = {}
		data[ question ] = { formSectionTitle, formSectionDescription }
		setData( state => ({
			...state,
			...data
		}))
	},[ formSectionTitle, formSectionDescription, question, setData ])
	
	return (
		<>
			<h3 className="form__section-title">{ formSectionTitle }</h3>
			{ formSectionDescription && <p className="form__section-description">{ formSectionDescription }</p> }
		</>
	)
}
