import React, { useState, useEffect } from 'react'

export default function Radio ( props ) {

	const { setData, question } = props
	const { radioQuestion, radioOptions, required } = props.input
	const [ checked, setChecked ] = useState([])

	const handleOnClick = e => {
		setChecked( e.target.value )
	}

	const generateId = ( label, index ) => {

		return `${ label.replace(/\s/g, "").slice(0, 7) }-${ index }`
	}

	useEffect(() => {
		const data = {}
		data[ question ] = { question: radioQuestion, answer: checked }
		setData( state => ({
			...state,
			...data
		}) )
	}, [ checked, question, radioQuestion, setData ])

	return (
		<div className="form__fields-wrapper">
			<p className="form__list-label">{ radioQuestion} { required ? '' : '(optional)' }</p>
			<div className="form__list">
				{ radioOptions.map( option => (
					<div className="form__input-container" key={ option.id }>
						<label className="form__checkbox-label" htmlFor={ generateId( option.option, option.id ) }>
							<input
								className="form__checkbox-input"
								name={`radio-${ question }`}
								type="radio"
								id={ generateId( option.option, option.id ) }
								onChange={ e => handleOnClick( e )}
								value={ option.option }
								required={ required } />
							<span className="form__checkbox-text">{ option.option }</span>
						</label>
					</div>
				))}
			</div>
		</div>
	)
}
