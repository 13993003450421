import React, { useState, useEffect } from 'react'

export default function RatingScale ( props ) {

	const { setData, question } = props
	const { ratingScaleText } = props.input
	const [ checked, setChecked ] = useState([])
	const scale = 10;

	const handleOnClick = e => {
		setChecked( e.target.value )
	}

	const generateId = ( label, index ) => ( `${ label.replace(/\s/g, "").slice(0, 7) }-${ question }-${ index }` )

	const generateUniqueInputName = label => ( `${ label.replace(/\s/g, "").slice(0, 7) }-${ question }` )

	useEffect(() => {
		const data = {}
		data[ question ] = { question: ratingScaleText, answer: checked }
		setData( state => ({
			...state,
			...data
		}) )
	}, [ checked, question, ratingScaleText, setData ])

	return (
		<div className="form__fields-wrapper">
			<p className="form__list-label">{ ratingScaleText}</p>
			<div className="form__list form__list--rating">
				{ [ ...Array( scale ) ].map( ( e, index ) => (
					<div className="form__input-container" key={ index + 1 }>
					<label className="form__checkbox-label" htmlFor={ generateId( ratingScaleText, index + 1 ) }>
						<input
							className="form__checkbox-input"
							name={ generateUniqueInputName( ratingScaleText ) }
							type="radio"
							id={ generateId( ratingScaleText, index + 1 ) }
							onChange={ e => handleOnClick( e )}
							value={ index + 1 }
							required />
						<span className="form__checkbox-text">{ index + 1 }</span>
					</label>
				</div>
				) ) }
			</div>
		</div>
	)
}
